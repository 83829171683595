<template>
	<div id="landOrderList">
		<page-plant-order :is-shop="0"></page-plant-order>
	</div>
</template>

<script>
	import pagePlantOrder from '@/components/layout/land/page-plant-order.vue'
	export default{
		components:{
			pagePlantOrder
		},
		beforeRouteLeave (to, from, next) {
		    from.meta.keepAlive = false;
		    next();
		},
		
	}
</script>

<style lang="scss">
</style>
